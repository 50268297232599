import { useEffect, useState } from 'react';
import { THEME_MODE } from '../components/configuration-options/slide-menu/types';

/**
 * A hook that sets the selected theme to and from the session storage.
 * @returns: theme and themeSetter
 **/
export function useTheme(catalogRef: string) {
  const [theme, setTheme] = useState<THEME_MODE>(THEME_MODE.DARK);

  /**
   * A function that stores the selected theme to the session storage
   * and sets the theme state.
   **/
  const setThemeWithSessionStorage = (selectedTheme: THEME_MODE) => {
    sessionStorage.setItem(`${catalogRef}-selectedTheme`, selectedTheme);
    setTheme(selectedTheme);
  };

  useEffect(() => {
    const selectedThemeInSS = sessionStorage.getItem(`${catalogRef}-selectedTheme`) as THEME_MODE;
    if (selectedThemeInSS) setTheme(selectedThemeInSS);
  }, [catalogRef]);

  return { theme, setTheme: setThemeWithSessionStorage };
}
