import { useEffect, useState, useRef } from 'react';
import { BID_TITLES, BoardBid } from '../board-bid';
import { BoardFooter } from '../board-footer';
import { BLINKER_COLORS } from './constants';
import { LotStatus } from '../lot-status';
import styles from './toteboard.module.scss';

import { IToteBoardProps } from './types';

export function ToteBoard({
  lotBidder,
  bidAmount,
  lotNumber,
  lotStatus,
  isOnlineBidSubmitted,
  submittedBidPaddle,
}: IToteBoardProps) {
  const [blinkerIndex, setBlinkerIndex] = useState(0);
  const paddleRef = useRef('');

  useEffect(() => {
    if (isOnlineBidSubmitted) {
      if (submittedBidPaddle != '' && paddleRef.current === submittedBidPaddle) {
        setBlinkerIndex(1);
      } else {
        switch (blinkerIndex) {
          case 0:
          case 2:
            setBlinkerIndex(1);
            break;
          case 1:
            setBlinkerIndex(2);
            break;
        }
      }
      paddleRef.current = submittedBidPaddle!;
    }
  }, [bidAmount, submittedBidPaddle]);

  const animationClass = isOnlineBidSubmitted
    ? `${styles['blinker-animation']} ${styles[BLINKER_COLORS[blinkerIndex]]}`
    : '';

  return (
    <div className={styles['board-main-holder']}>
      <div className={styles['tote-board-main']}>
        <LotStatus status={lotStatus} />
        <BoardBid
          animationClass={animationClass}
          bidTitle={isOnlineBidSubmitted ? BID_TITLES.SUBMITTED_BID : BID_TITLES.ACCEPTED_BID}
          bidAmount={bidAmount}
        />
        <BoardFooter animationClass={animationClass} lotNumber={lotNumber} lotBidder={lotBidder} />
      </div>
    </div>
  );
}
