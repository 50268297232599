export enum LOT_BIDDERS {
  ONLINE = 'Online',
  FLOOR = 'Floor',
}

export type lotBidderType = LOT_BIDDERS.ONLINE | LOT_BIDDERS.FLOOR | number;

export interface IBoardFooterProps {
  lotNumber?: string;
  lotBidder?: lotBidderType;
  animationClass?: string;
}
