export enum GQL_RESPONSE_STATUSES {
  SUCCESS = 7,
}

export interface IStandardResponse {
  data: Record<string, unknown> | unknown;
  statusCode: number;
  statusMessage: string;
  success: boolean;
}
