import { gql } from '@apollo/client';

import GQLService from '../clients/gql';

import { TCatalogResponse, TCatalog } from './types';

/**
 * CatalogService contains catalog related GQL queries
 * */
class CatalogService extends GQLService {
  /**
   * Executes catalog query for given data to be fetched
   * @param catalogRef
   * @param query: data to be fetched
   */
  fetchCatalog = async (catalogRef: string, query: string): Promise<TCatalog> => {
    const catalogQuery = gql`
      query CatalogQuery($catalogRef: String!) {
        catalog(catalogRef: $catalogRef) ${query}
    }`;

    const catalogResponse = await this.query(catalogQuery, { catalogRef });
    return (catalogResponse.data as TCatalogResponse)?.catalog;
  };
}

export const catalogService = new CatalogService();
