import { useEffect, useState } from 'react';

/**
 * A hook that sets the selected currencies to and from the session storage.
 * @returns: selectedCurrencies and selectedCurrenciesSetter
 **/
export function useSelectedCurrencies(catalogRef: string) {
  const [selectedCurrencies, setSelectedCurrencies] = useState<string[]>([]);

  /**
   * A function that sets the selected currencies to the session storage
   * and sets the selectedCurrencies state.
   **/
  const setSelectedCurrenciesWithSessionStorage = (updatedSelectedCurrencies: string[]) => {
    sessionStorage.setItem(`${catalogRef}-selectedCurrencies`, JSON.stringify(updatedSelectedCurrencies));
    setSelectedCurrencies(updatedSelectedCurrencies);
  };

  useEffect(() => {
    const selectedCurrenciesInSS = sessionStorage.getItem(`${catalogRef}-selectedCurrencies`);
    if (selectedCurrenciesInSS) setSelectedCurrencies(JSON.parse(selectedCurrenciesInSS));
  }, [catalogRef]);

  return { selectedCurrencies, setSelectedCurrencies: setSelectedCurrenciesWithSessionStorage };
}
