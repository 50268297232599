import React, { useState } from 'react';

import { SlideMenu } from './slide-menu/slide-menu';

import styles from './configuration.module.scss';

export function ConfigurationOptions() {
  const [isSlidMenuOpen, setIsSlideMenuOpen] = useState(false);

  return (
    <div className={`${styles['settings-icon-holder']} ${isSlidMenuOpen ? styles['slide-open'] : ''}`}>
      <button className={styles['gear-btn']} onClick={() => setIsSlideMenuOpen(!isSlidMenuOpen)}>
        <img alt='close' src='/images/cog-solid.svg' />
      </button>

      {isSlidMenuOpen && <SlideMenu setIsSlideMenuOpen={setIsSlideMenuOpen} />}
    </div>
  );
}
