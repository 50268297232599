import React from 'react';
import { TEXT_OPTIONS } from '../components/configuration-options/slide-menu/types';
import { ITextConfigurationOptions } from './types';

const defaultState = {
  textOption: TEXT_OPTIONS.SHOW_BID,
  setTextOption: () => {
    return;
  },
};

export const CTextConfigurationOptions = React.createContext<ITextConfigurationOptions>(defaultState);
