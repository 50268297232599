import { useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CCurrencyConfigurationOptions } from '../../../contexts';

import { Bid } from '../bid';
import { reorderItems } from './helpers';
import { IDragableBidsProps, IDragEndResult } from './types';

export function DragableBids({ amount }: IDragableBidsProps) {
  const { selectedCurrencies, exchangeRates, setSelectedCurrencies } = useContext(CCurrencyConfigurationOptions);

  const handleDragEnd = (result: IDragEndResult) => {
    const { destination, source } = result || {};

    if (!destination) {
      return;
    }

    const orderedCurrencies = reorderItems(selectedCurrencies, source.index, destination.index);

    setSelectedCurrencies(orderedCurrencies as string[]);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='droppable'>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {selectedCurrencies.map((currency: string, index: number) => (
              <Draggable key={currency} draggableId={currency} index={index}>
                {_provided => (
                  <div ref={_provided.innerRef} {..._provided.draggableProps} {..._provided.dragHandleProps}>
                    {exchangeRates[currency] && (
                      <Bid key={currency} title={currency} amount={Math.round(amount * exchangeRates[currency])} />
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
