import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'src/common/components/loader';
import WebSocketClient from 'src/common/services/clients/websockets';
import { TBrokerMessage, WEB_SOCKET_EVENTS } from 'src/common/services/clients/websockets/types';
import { useLotImageOption } from 'src/saleroom/hooks/use-lot-image-option';

import {
  useFetchCurrencyCodeAndLots,
  useFetchExchangeRatesForSupportedCurrencies,
  useSelectedCurrencies,
  useTextOptions,
  useTheme,
} from '../hooks';

import { ConfigurationContext, ConfigurationOptions } from './configuration-options';
import { CURRENCIES } from './configuration-options/slide-menu/constants';
import { SaleRoom } from './sale-room';
import { ICurrentLot } from './sale-room/types';
import { getLotData } from './utils';

export function SaleRoomWrapper() {
  const catalogRef = (useParams().catalogRef as string) || '';

  const [areLotsLoading, lots, defaultCurrencyCode] = useFetchCurrencyCodeAndLots(catalogRef);
  const exchangeRates = useFetchExchangeRatesForSupportedCurrencies(defaultCurrencyCode);

  const { lotImageOption, setLotImageOption } = useLotImageOption(catalogRef);
  const { textOption, setTextOption } = useTextOptions(catalogRef);
  const { theme, setTheme } = useTheme(catalogRef);
  const { selectedCurrencies, setSelectedCurrencies } = useSelectedCurrencies(catalogRef);

  const [currentLot, setCurrentLot] = useState<ICurrentLot | undefined>(undefined);
  const [currentLotImage, setCurrentLotImage] = useState<string>('');
  const [currencies, setCurrencies] = useState<string[]>([]);

  const saleRoomWebSocketsMessageHandler = (message: TBrokerMessage) => {
    switch (message.event) {
      case WEB_SOCKET_EVENTS.ASK:
      case WEB_SOCKET_EVENTS.LOT:
      case WEB_SOCKET_EVENTS.NEXT_UNSOLD:
      case WEB_SOCKET_EVENTS.UNDO_BID:
      case WEB_SOCKET_EVENTS.UNDO_SELL:
        setCurrentLot(getLotData(message.data));
        break;
    }
  };

  useEffect(() => {
    let availableCurrencies = Object.keys(exchangeRates);
    //If USD is present, move it to the top of the list of currencies
    if (availableCurrencies.includes(CURRENCIES.USD)) {
      availableCurrencies = availableCurrencies.filter(currency => currency !== CURRENCIES.USD);
      availableCurrencies = [CURRENCIES.USD, ...availableCurrencies];
    }
    setCurrencies(availableCurrencies);
  }, [exchangeRates]);

  useEffect(() => {
    catalogRef && WebSocketClient.setOnConnectEventHandler(saleRoomWebSocketsMessageHandler, catalogRef);
  }, [catalogRef]);

  useEffect(() => {
    return () => {
      WebSocketClient.terminateSocket();
    };
  }, []);

  useEffect(() => {
    const { primaryImage } = (lots && currentLot && lots[+currentLot.itemId]) || {};
    primaryImage && setCurrentLotImage(primaryImage?.largeUrl);
  }, [currentLot?.itemId, lots]);

  const renderSaleRoom = () => (
    <ConfigurationContext
      {...{
        currencies,
        exchangeRates,
        selectedCurrencies,
        setSelectedCurrencies,
        textOption,
        setTextOption,
        theme,
        setTheme,
        lotImageOption,
        setLotImageOption,
      }}
    >
      <SaleRoom defaultCurrencyCode={defaultCurrencyCode} currentLot={currentLot} currentLotImage={currentLotImage} />
      <ConfigurationOptions />
    </ConfigurationContext>
  );

  return areLotsLoading ? <Loader /> : renderSaleRoom();
}
