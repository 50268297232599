import { ICurrentLot } from '../sale-room/types';

export interface IBidSectionWrapperProps {
  defaultCurrency: string;
  currentLot: ICurrentLot;
}

export enum BID_SECTION_TYPES {
  ASK = 'Ask',
  BID = 'Bid',
}

export interface IBidSectionProps {
  defaultCurrency: string;
  amount: number;
  sectionType?: BID_SECTION_TYPES.ASK | BID_SECTION_TYPES.BID;
}

export interface ICurrencyRowProps {
  title: string;
  amount?: number;
  bold?: boolean;
}
