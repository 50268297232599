import { useEffect, useState } from 'react';
import { LOT_IMAGE_OPTIONS } from '../components/configuration-options/slide-menu/types';

/**
 * A hook that sets the selected lotImageOption to and from the session storage.
 * @returns: lotImageOption and lotImageOptionSetter
 **/
export function useLotImageOption(catalogRef: string) {
  const [lotImageOption, setLotImageOption] = useState<LOT_IMAGE_OPTIONS>(LOT_IMAGE_OPTIONS.LOT_IMAGE_AND_TEXT);

  /**
   * A function that stores the selected lotImageOption to the session storage
   * and sets the lotImageOption state.
   **/
  const setLotImageOptionWithSessionStorage = (selectedLotImageOption: LOT_IMAGE_OPTIONS) => {
    sessionStorage.setItem(`${catalogRef}-selectedLotImageOption`, selectedLotImageOption);
    setLotImageOption(selectedLotImageOption);
  };

  useEffect(() => {
    const selectedLotImageOptionInSS = sessionStorage.getItem(
      `${catalogRef}-selectedLotImageOption`,
    ) as LOT_IMAGE_OPTIONS;
    if (selectedLotImageOptionInSS) setLotImageOption(selectedLotImageOptionInSS);
  }, [catalogRef]);

  return { lotImageOption, setLotImageOption: setLotImageOptionWithSessionStorage };
}
