import { useContext } from 'react';
import { CLotImageConfigurationOptions, CThemeConfigurationOptions } from '../../contexts';
import { LotInfo } from '../lot-info';

import { LOT_IMAGE_OPTIONS } from '../configuration-options/slide-menu/types';
import { ISaleRoomProps } from './types';

import styles from './sale-room.module.scss';

export function SaleRoom({ defaultCurrencyCode, currentLot, currentLotImage }: ISaleRoomProps) {
  const { theme } = useContext(CThemeConfigurationOptions);
  const { lotImageOption } = useContext(CLotImageConfigurationOptions);
  let lotImageOptionClass = '';

  if (lotImageOption === LOT_IMAGE_OPTIONS.FULL_LOT_IMAGE) {
    lotImageOptionClass = 'full-lot-img';
  } else if (lotImageOption === LOT_IMAGE_OPTIONS.FULL_LOT_IMAGE_WITH_LOT_NUMBER) {
    lotImageOptionClass = 'img-with-text';
  }

  return (
    <div className={`${styles['sale-room-holder']} ${styles[lotImageOptionClass]} ${styles[theme]}`}>
      <div className={styles['title-lot-number']}>
        <h1>Lot {currentLot?.lotNumber}</h1>
      </div>

      <div className={styles['img-container']}>{currentLotImage && <img src={currentLotImage} alt='' />}</div>

      <div className={styles['lot-info-container']}>
        <LotInfo defaultCurrency={defaultCurrencyCode} currentLot={currentLot} />
      </div>
    </div>
  );
}
