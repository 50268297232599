import { useContext } from 'react';

import { BackgroundColorOptions } from './background-color-options';
import { CurrencyOptions } from './currency-options';
import { CURRENCY_OPTIONS_ON_SHOW_BID, CURRENCY_OPTIONS_ON_SHOW_BID_AND_ASK } from './constants';
import { ISlideMenuProps, TEXT_OPTIONS } from './types';
import { LotImageOptions } from './lot-image-options';
import { TextOptions } from './text-options';

import { CCurrencyConfigurationOptions, CTextConfigurationOptions } from '../../../contexts';

import styles from '../configuration.module.scss';

export function SlideMenu({ setIsSlideMenuOpen }: ISlideMenuProps) {
  const { selectedCurrencies } = useContext(CCurrencyConfigurationOptions);
  const { textOption } = useContext(CTextConfigurationOptions);

  const allowedCurrencies =
    textOption === TEXT_OPTIONS.SHOW_BID ? CURRENCY_OPTIONS_ON_SHOW_BID : CURRENCY_OPTIONS_ON_SHOW_BID_AND_ASK;

  return (
    <div className={styles['configuration-slide-menu']}>
      <div className={styles['head']}>
        <h4>Configuration Options</h4>
        <button className={styles['close-btn']} onClick={() => setIsSlideMenuOpen(false)}>
          x
        </button>
      </div>
      <div className={styles['main-widget']}>
        <h4>Select up to {allowedCurrencies - selectedCurrencies.length} additional currencies:</h4>
      </div>
      <CurrencyOptions />

      <LotImageOptions />

      <TextOptions />

      <BackgroundColorOptions />
    </div>
  );
}
