/**
 * This file allows accessing environment variables from
 * a central place in the codebase.
 *
 * It also helps in setting up default values for env-
 * variables in case they are omitted from the actual
 * env files. */

export const ENV = {
  AWS_APPSYNC_API_KEY: process.env.REACT_APP_AWS_APPSYNC_API_KEY || '',
  AWS_APPSYNC_REGION: process.env.REACT_APP_AWS_APPSYNC_REGION || '',
  GRAPHQL_BASE_URL: process.env.REACT_APP_GRAPHQL_BASE_URL || '',
  WEBSOCKET_BROKER_BASE_URL: process.env.REACT_APP_WEBSOCKET_BROKER_BASE_URL || '',
  TOTE_BOARD_LOT_SELL_PASS_DELAY_MS: process.env.REACT_APP_TOTE_BOARD_LOT_SELL_PASS_DELAY_MS
    ? parseInt(process.env.REACT_APP_TOTE_BOARD_LOT_SELL_PASS_DELAY_MS)
    : 5000,
  DEVELOPMENT: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
};
