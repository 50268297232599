export enum LOT_STATUSES {
  SOLD = 'SOLD',
  UNSOLD = 'UNSOLD',
}

export type lotStatusType = LOT_STATUSES.SOLD | LOT_STATUSES.UNSOLD;

export interface ILotStatusProps {
  status?: lotStatusType;
}
