import { ApolloClient, ApolloLink, createHttpLink, DocumentNode, InMemoryCache } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';

import { ENV } from '../../../configs/env';
import { prepareGenericResponse } from '../utils';
import { IStandardResponse } from '../utils/types';

const link = ApolloLink.from([
  createAuthLink({
    url: ENV.GRAPHQL_BASE_URL,
    region: ENV.AWS_APPSYNC_REGION,
    auth: {
      type: 'API_KEY',
      apiKey: ENV.AWS_APPSYNC_API_KEY,
    },
  }),
  createHttpLink({ uri: ENV.GRAPHQL_BASE_URL }),
]);

/**
 * Generic service to wrap basic GQL client methods and return standard response
 */
export default class GQLService {
  private client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  /**
   * Base query method that queries to GQL and returns IStandardResponse
   * */
  async query(
    query: DocumentNode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    variables: Record<string, any>,
  ): Promise<IStandardResponse> {
    try {
      const response = await this.client.query({
        query,
        variables,
      });
      return prepareGenericResponse(response);
    } catch (error) {
      return prepareGenericResponse(error);
    }
  }
}
