/**
 * A utility function to get the ordered items
 * @param items: list of data to be ordered
 * @param sourceIndex: index of item to be moved from
 * @param destinationIndex: index of item to be placed at
 * @returns: returns ordered list of data
 */
export const reorderItems = (items: string[], sourceIndex: number, destinationIndex: number) => {
  const orderedItems = Array.from(items);
  const [removed] = orderedItems.splice(sourceIndex, 1);
  orderedItems.splice(destinationIndex, 0, removed);

  return orderedItems;
};
