import React from 'react';
import { ICurrencyConfigurationOptions } from './types';

const defaultState = {
  currencies: [],
  selectedCurrencies: [],
  setSelectedCurrencies: () => {
    return;
  },
  exchangeRates: {},
};

export const CCurrencyConfigurationOptions = React.createContext<ICurrencyConfigurationOptions>(defaultState);
