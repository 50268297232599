import { ICurrentLot } from '../sale-room/types';

export interface ILotInfoProps {
  defaultCurrency: string;
  currentLot?: ICurrentLot;
}

export enum BIDDER_TYPE {
  FLOOR = 'Floor',
  ONLINE = 'Online',
}
