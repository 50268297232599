export interface ISlideMenuProps {
  setIsSlideMenuOpen: (val: boolean) => void;
}

export enum TEXT_OPTIONS {
  SHOW_BID = 'Show Bid',
  SHOW_BID_AND_ASK = 'Show Bid & Ask',
}

export type TTextOptions = TEXT_OPTIONS.SHOW_BID | TEXT_OPTIONS.SHOW_BID_AND_ASK;

export enum LOT_IMAGE_OPTIONS {
  LOT_IMAGE_AND_TEXT = 'Lot Image and Text',
  FULL_LOT_IMAGE = 'Full Lot Image',
  FULL_LOT_IMAGE_WITH_LOT_NUMBER = 'Full Image with Lot Number',
}

export enum THEME_MODE {
  LIGHT = 'light-theme',
  DARK = 'black',
}
