import { useEffect, useState } from 'react';
import { exchangeRatesService } from '../services/exchange-rates';
import { filterExchangeRatesOfSupportedCurrencies } from './helpers';
import { IExchangeRate } from './types';

/**
 * A hook that fetches exchange rates based on the default currency.
 * @param defaultCurrency: string
 * @returns: exchangeRates:IExchangeRate
 **/
export function useFetchExchangeRatesForSupportedCurrencies(defaultCurrency: string): IExchangeRate {
  const [exchangeRates, setExchangeRates] = useState<IExchangeRate>({});

  useEffect(() => {
    /**
     * Fetches exchange rates.
     * Created to call async logic in the use Effect and is immediately invoked.
     * */
    async function fetchExchangeRates() {
      const fetchedExchangeRates = await exchangeRatesService.fetchExchangeRates(defaultCurrency as string);
      if (fetchedExchangeRates) {
        setExchangeRates(filterExchangeRatesOfSupportedCurrencies(fetchedExchangeRates));
      }
    }

    fetchExchangeRates();
  }, [defaultCurrency]);

  return exchangeRates;
}
