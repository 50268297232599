import React, { useContext } from 'react';

import { CLotImageConfigurationOptions, CThemeConfigurationOptions } from 'src/saleroom/contexts';
import { LOT_IMAGE_OPTIONS, THEME_MODE } from './types';

import styles from '../configuration.module.scss';

export function LotImageOptions() {
  const { lotImageOption, setLotImageOption } = useContext(CLotImageConfigurationOptions);
  const { setTheme } = useContext(CThemeConfigurationOptions);

  function lotImageOptionToggleHandler(event: React.SyntheticEvent) {
    const selectedLotImageOption = (event.target as HTMLInputElement).value as LOT_IMAGE_OPTIONS;
    if (selectedLotImageOption === LOT_IMAGE_OPTIONS.LOT_IMAGE_AND_TEXT) {
      setTheme(THEME_MODE.DARK);
    }
    setLotImageOption(selectedLotImageOption);
  }

  return (
    <div className={styles['main-widget']}>
      <h4>Lot image options:</h4>
      <form className={styles['radiobox-list']}>
        <ul>
          {Object.values(LOT_IMAGE_OPTIONS).map(imageOption => (
            <li key={imageOption}>
              <label>
                <input
                  checked={imageOption === lotImageOption}
                  onChange={lotImageOptionToggleHandler}
                  type='radio'
                  value={imageOption || ''}
                  name='imgOption'
                />{' '}
                {imageOption}
              </label>
            </li>
          ))}
        </ul>
      </form>
    </div>
  );
}
