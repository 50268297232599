import { BIDDER_TYPE } from './types';

/**
 * A utility function to get the bidder type
 * @param bidderType: string, bidder type
 * @returns: returns bidder type in a specific format
 */
export const getBidderType = (bidderType: string) => {
  if (!bidderType || bidderType === BIDDER_TYPE.FLOOR.toLocaleLowerCase()) return BIDDER_TYPE.FLOOR;
  return BIDDER_TYPE.ONLINE;
};
