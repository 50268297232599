/**
 * Converts a list of objects to an object, that contains all the objects against the given key
 * Example input: [{id: 1, title: 'title 1'}, {id: 2, title: 'title 2'}]
 * Output, with key=id: {1: {id: 1, title: 'title 1'}, 2: {id: 2, title: 'title 2'}}
 * @param array: Array of object to be converted
 * @param key: String, key that would be used for the conversion
 * @param startingObject: Object, Initial object, array elements would be adjusted in this object if provided
 * */
export const getObjectByKeys = (array: Array<any> = [], key: string, startingObject = {}): Record<string, any> =>
  // eslint-disable-next-line no-return-assign
  array?.reduce(
    (obj: any, item: any) => (
      // eslint-disable-next-line no-sequences
      (obj[item[key]] = item), obj
    ),
    { ...startingObject },
  );
