import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import { useWebSocketEvents } from '../hooks/useWebSocketEvents';
import { Metadata } from './header';
import { ToteBoard } from './toteboard';
import { Loader } from '../../common/components/loader';
import { catalogService } from '../../common/services/catalog';

export function ToteBoardWrapper() {
  const catalogRef = (useParams().catalogRef as string) || '';

  const [toteBoardTitle, setToteBoardTitle] = useState('Tote Board');
  const [isCatalogLoading, setIsCatalogLoading] = useState(false);
  const lotData = useWebSocketEvents(catalogRef);

  useEffect(() => {
    async function fetchCatalogTitle() {
      setIsCatalogLoading(true);
      const catalog = await catalogService.fetchCatalog(
        catalogRef as string,
        `{
        title
      }`,
      );
      catalog?.title && setToteBoardTitle(catalog.title);
      setIsCatalogLoading(false);
    }

    fetchCatalogTitle();
  }, []);

  const renderToteboard = () => (
    <HelmetProvider>
      <Metadata title={toteBoardTitle} />
      <ToteBoard {...lotData} />
    </HelmetProvider>
  );

  return isCatalogLoading ? <Loader /> : renderToteboard();
}
