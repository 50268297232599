import { TExchangeRate } from '../services/exchange-rates/types';
import { SUPPORTED_CURRENCIES } from '../components/configuration-options/slide-menu/constants';

import { IExchangeRate } from './types';

/**
 * Filters the exchange rates of supported currencies
 * */
export function filterExchangeRatesOfSupportedCurrencies(fetchedExchangeRates: Array<TExchangeRate>) {
  const exchangeRatesData: IExchangeRate = {};
  for (const exchangeRate of fetchedExchangeRates) {
    if (SUPPORTED_CURRENCIES.includes(exchangeRate.curName)) {
      exchangeRatesData[exchangeRate.curName] = exchangeRate.curRate;
    }
  }
  return exchangeRatesData;
}
