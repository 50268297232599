import { IStandardResponse, GQL_RESPONSE_STATUSES } from './types';

/**
 * Prepares a generic response to be sent to Action Layer
 * Purpose is to handle all API response inconsistencies at this level
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, require-jsdoc
export const prepareGenericResponse = (response: any): IStandardResponse => {
  if (!!response.success || response.networkStatus === GQL_RESPONSE_STATUSES.SUCCESS || !response.errors) {
    return {
      data: response.data,
      statusCode: 200,
      statusMessage: 'Request processed successfully',
      success: true,
    };
  }

  // error case
  return {
    data: null,
    statusCode: 500,
    statusMessage: 'Request failed',
    success: false,
  };
};
