import React from 'react';
import { LOT_IMAGE_OPTIONS } from '../components/configuration-options/slide-menu/types';
import { ILotImageConfigurationOptions } from './types';

const defaultState = {
  lotImageOption: LOT_IMAGE_OPTIONS.LOT_IMAGE_AND_TEXT,
  setLotImageOption: () => {
    return;
  },
};

export const CLotImageConfigurationOptions = React.createContext<ILotImageConfigurationOptions>(defaultState);
