import { BID_TITLES, IBoardBidProps } from './types';
import styles from './board-bid.module.scss';

export function BoardBid({ bidTitle, bidAmount = 0, animationClass = '' }: IBoardBidProps) {
  return (
    <div className={`${styles['bid']} ${animationClass}`}>
      <div className={`${styles['title']} ${bidTitle === BID_TITLES.SUBMITTED_BID ? styles['new-bid'] : ''}`}>
        <span>{bidTitle}</span>
      </div>
      <div className={styles['amount-bid']}>
        <span>{bidAmount.toLocaleString()}</span>
      </div>
    </div>
  );
}
