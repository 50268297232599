import { ActivationState, Client } from '@stomp/stompjs';
import { ENV } from '../../../configs/env';

import {
  INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS,
  MS_IN_A_SECOND,
  STOMP_CONFIG,
  WEBSOCKET_ENDPOINTS,
} from './constants';
import { TBrokerMessage, WEBSOCKET_CLOSE_STATUSES } from './types';
import { getParsedBrokerMessage, getWSConnectionIdentifier } from './utils';

/**
 * A singleton that creates and manages websocket client.
 * This class provides method for creating a new client, setting
 * onConnectEventHandler, activating and terminating client.
 */
export default class WebSocketClient {
  private static client = WebSocketClient.getClient();
  private static currentTry = 0;

  /**
   * A static member that parses the event stream message to TBrokerMessage object
   * @param webSocketEventHandler:(parsedData:TBrokerMessage)=>void callback method
   * @param catalogRef:string catalog reference
   * @returns {TBrokerMessage} parsed TBrokerMessage object
   */
  public static setOnConnectEventHandler(
    webSocketEventHandler: (parsedData: TBrokerMessage) => void,
    catalogRef: string,
  ): void {
    WebSocketClient.client.onConnect = () => {
      WebSocketClient.currentTry = 0;
      WebSocketClient.client.reconnectDelay = STOMP_CONFIG.INIT_RECONNECTION_DELAY_MS;
      const wsConnectionIdentifier = getWSConnectionIdentifier(catalogRef);
      WebSocketClient.client.subscribe(
        `${WEBSOCKET_ENDPOINTS.BROKER_SUBSCRIPTION}/${catalogRef}-SRTB-WATCHER-${wsConnectionIdentifier}`,
        msg => {
          if (msg?.body) {
            const jsonBody = JSON.parse(msg.body);
            if (jsonBody?.text) {
              const parsedData = getParsedBrokerMessage(jsonBody.text);
              webSocketEventHandler(parsedData);
            }
          }
        },
        { 'Last-Event-ID': '' },
      );
    };
    WebSocketClient.activateSocket();
  }

  /**
   * A static member that terminates the socket client connection.
   */
  public static terminateSocket() {
    if (WebSocketClient.client.state === ActivationState.ACTIVE) {
      /**
       If we deactivate the socket and try to reactivate it too soon, as is the case with fast reload
       it will throw an error that the client is still disconnecting, So we forceDisconnect
       the client before deactivating
       **/
      WebSocketClient.client.forceDisconnect();
      WebSocketClient.client.deactivate();
    }
  }

  /**
   * A static member that returns the stomp-js websocket client.
   * It first creates a stomp-js websocket client if not created.
   */
  private static getClient(): Client {
    const client = new Client({
      brokerURL: WEBSOCKET_ENDPOINTS.BROKER,
      reconnectDelay: STOMP_CONFIG.INIT_RECONNECTION_DELAY_MS,
      heartbeatIncoming: STOMP_CONFIG.HEARTBEAT_INCOMING_MS,
      heartbeatOutgoing: STOMP_CONFIG.HEARTBEAT_OUTGOING_MS,
      discardWebsocketOnCommFailure: STOMP_CONFIG.DISCARD_WEBSOCKET,
    });
    client.onWebSocketClose = WebSocketClient.onWebSocketClose;
    client.beforeConnect = WebSocketClient.beforeConnect;
    return client;
  }

  /**
   * A static member that is called before the client attempts to connect to the broker
   */
  private static beforeConnect() {
    WebSocketClient.currentTry++;
    if (WebSocketClient.currentTry % INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS === 0) {
      WebSocketClient.client.reconnectDelay =
        STOMP_CONFIG.INIT_RECONNECTION_DELAY_MS +
        (WebSocketClient.currentTry / INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS) * MS_IN_A_SECOND;
    }

    ENV.DEVELOPMENT &&
      console.log(
        `Connection attempt:${WebSocketClient.currentTry}, Reconnect Delay:${WebSocketClient.client.reconnectDelay}ms`,
      );
  }

  /**
   * A static member that activates the socket client. If the client is already connected, it first terminates the
   * client and then attempts to reactivate.
   */
  private static activateSocket() {
    WebSocketClient.client.activate();
  }

  /**
   * A static member that is called when the websocket connection is closed.
   */
  private static onWebSocketClose(event: CloseEvent) {
    if (event.code === WEBSOCKET_CLOSE_STATUSES.UNACCEPTABLE) {
      WebSocketClient.terminateSocket();
      return;
    }
  }
}
