import { useContext } from 'react';
import { CTextConfigurationOptions } from '../../contexts';
import { TEXT_OPTIONS } from '../configuration-options/slide-menu/types';

import { Bid } from './bid';
import { IBidSectionProps } from './types';
import { DragableBids } from './drag-able-bids';

import styles from './bid-section.module.scss';

export function BidSection({ defaultCurrency, amount, sectionType }: IBidSectionProps) {
  const { textOption } = useContext(CTextConfigurationOptions);

  return (
    <div className={styles['bas-bid-section']}>
      {textOption === TEXT_OPTIONS.SHOW_BID_AND_ASK && (
        <div className={`${styles['bas-currency-row']} ${styles['main-bold']}`}>
          <span className={styles['currency-label']}>{sectionType}</span>
        </div>
      )}
      <Bid key={defaultCurrency} bold title={defaultCurrency} amount={amount} />
      <DragableBids amount={amount} />
    </div>
  );
}
