import { BidSectionWrapper } from '../bid-section';
import { getBidderType } from './helpers';
import styles from './lot-info.module.scss';
import { ILotInfoProps } from './types';

export function LotInfo({ defaultCurrency, currentLot }: ILotInfoProps) {
  return (
    <div className={styles['lot-info-sec']}>
      <div className={styles['lot-info-row']}>
        <span className={styles['lot-number']}>Lot {currentLot?.lotNumber}</span>
        <span className={styles['current-winner']}>{currentLot && getBidderType(currentLot.currentWinner)}</span>
      </div>

      {currentLot && <BidSectionWrapper defaultCurrency={defaultCurrency} currentLot={currentLot} />}
    </div>
  );
}
