import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PageNotFound404 } from 'src/common/components/404-page-not-found';

import { SaleRoomWrapper } from './saleroom/components';
import { ToteBoardWrapper } from './toteboard/components';

import 'src/common/styles/global.scss';

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/saleroom/:catalogRef' element={<SaleRoomWrapper />} />
        <Route path='/toteboard/:catalogRef' element={<ToteBoardWrapper />} />
        <Route path='*' element={<PageNotFound404 />} />
      </Routes>
    </BrowserRouter>
  );
}
