import React from 'react';
import { IThemeConfigurationOptions } from './types';
import { THEME_MODE } from '../components/configuration-options/slide-menu/types';

const defaultState = {
  theme: THEME_MODE.DARK,
  setTheme: () => {
    return;
  },
};

export const CThemeConfigurationOptions = React.createContext<IThemeConfigurationOptions>(defaultState);
