export const CURRENCY_OPTIONS_ON_SHOW_BID = 7;
export const CURRENCY_OPTIONS_ON_SHOW_BID_AND_ASK = 2;

export const SUPPORTED_CURRENCIES = [
  'USD',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'IDR',
  'INR',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NGN',
  'NZD',
  'PHP',
  'PLN',
  'RUB',
  'SEK',
  'SGD',
  'TWD',
  'ZAR',
];

export enum CURRENCIES {
  USD = 'USD',
}
