import { RADIX_FOR_WS_CONNECTION_IDENTIFIER } from './constants';
import { TBrokerMessage } from './types';

/**
 * A utility that returns a unique wsConnectionIdentifier, it would check for the identifier
 * in the session storage first if its not in the session storage it would generate a new one,
 * save it to session storage and return it
 * @param catalogRef : string Catalog reference
 * @returns {string} A unique alphanumeric string that is used to identify websocket connection
 */
export const getWSConnectionIdentifier = (catalogRef: string): string => {
  let wsConnectionIdentifier = sessionStorage.getItem(`${catalogRef}_ws_connection_identifier`);
  if (!wsConnectionIdentifier) {
    wsConnectionIdentifier = (Math.random() + 1).toString(RADIX_FOR_WS_CONNECTION_IDENTIFIER).substring(2);
    sessionStorage.setItem(`${catalogRef}_ws_connection_identifier`, wsConnectionIdentifier);
  }
  return wsConnectionIdentifier;
};

/**
 * A utility that parses the event stream message to TBrokerMessage object
 * @param message : string  [message="id: 0 event: lot data: {"auctionId":"7RQVL2WRO1"...}"] -
 * JSON parsed string from event stream of StompJs
 * @returns {TBrokerMessage} parsed TBrokerMessage object
 */
export const getParsedBrokerMessage = (message: string): TBrokerMessage => {
  const messageData = message.substring(message.indexOf('{'), message.length - 1);
  const eventType = message.substring(message.indexOf(':') + 1, message.indexOf('data')).split('event:');
  const messageParsedData = JSON.parse(messageData);
  // eventIdOrName : because in case of eventType = event/lot, id will not come from server
  const [eventIdOrName, eventName] = eventType;
  return {
    event: eventType.length === 2 ? eventName?.trim() : eventIdOrName?.trim(),
    data: messageParsedData,
  };
};
