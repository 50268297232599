import React, { useContext } from 'react';
import { CLotImageConfigurationOptions, CTextConfigurationOptions } from '../../../contexts';
import styles from '../configuration.module.scss';
import { LOT_IMAGE_OPTIONS, TEXT_OPTIONS, TTextOptions } from './types';

export function TextOptions() {
  const { textOption, setTextOption } = useContext(CTextConfigurationOptions);
  const { lotImageOption } = useContext(CLotImageConfigurationOptions);

  function textOptionToggleHandler(event: React.SyntheticEvent) {
    const selectedTextOption = (event.target as HTMLInputElement).name as TTextOptions;

    if (selectedTextOption !== textOption) setTextOption(selectedTextOption);
  }

  return (
    <div className={styles['main-widget']}>
      <h4>Text options:</h4>
      <form className={styles['radiobox-list']}>
        <ul>
          {Object.values(TEXT_OPTIONS).map(textOpt => (
            <li key={textOpt}>
              <label>
                <input
                  disabled={lotImageOption !== LOT_IMAGE_OPTIONS.LOT_IMAGE_AND_TEXT}
                  type='radio'
                  name={textOpt}
                  checked={textOpt === textOption}
                  onChange={textOptionToggleHandler}
                />{' '}
                {textOpt as string}
              </label>
            </li>
          ))}
        </ul>
      </form>
    </div>
  );
}
