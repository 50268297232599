import { useContext } from 'react';

import { CTextConfigurationOptions } from '../../contexts';
import { TEXT_OPTIONS } from '../configuration-options/slide-menu/types';

import { BidSection } from './bid-section';
import { BID_SECTION_TYPES, IBidSectionWrapperProps } from './types';
import styles from './bid-section.module.scss';

export function BidSectionWrapper({ defaultCurrency, currentLot }: IBidSectionWrapperProps) {
  const { textOption } = useContext(CTextConfigurationOptions);
  const { askPrice: askAmount, currentBid: currentBidAmount } = currentLot || {};

  return (
    <div className={`${styles['bas-currency-list']}`}>
      {textOption === TEXT_OPTIONS.SHOW_BID_AND_ASK && (
        <BidSection amount={+askAmount} defaultCurrency={defaultCurrency} sectionType={BID_SECTION_TYPES.ASK} />
      )}
      <BidSection
        amount={+currentBidAmount}
        defaultCurrency={defaultCurrency}
        sectionType={textOption === TEXT_OPTIONS.SHOW_BID_AND_ASK ? BID_SECTION_TYPES.BID : undefined}
      />
    </div>
  );
}
