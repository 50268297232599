import {
  CCurrencyConfigurationOptions,
  CLotImageConfigurationOptions,
  CTextConfigurationOptions,
  CThemeConfigurationOptions,
} from '../../contexts';
import { IConfigurationContextProps } from './types';

export function ConfigurationContext({
  children,
  currencies,
  exchangeRates,
  selectedCurrencies,
  setSelectedCurrencies,
  textOption,
  setTextOption,
  theme,
  setTheme,
  lotImageOption,
  setLotImageOption,
}: IConfigurationContextProps) {
  return (
    <CCurrencyConfigurationOptions.Provider
      value={{
        currencies,
        exchangeRates,
        selectedCurrencies,
        setSelectedCurrencies,
      }}
    >
      <CTextConfigurationOptions.Provider
        value={{
          textOption,
          setTextOption,
        }}
      >
        <CThemeConfigurationOptions.Provider
          value={{
            theme,
            setTheme,
          }}
        >
          <CLotImageConfigurationOptions.Provider
            value={{
              lotImageOption,
              setLotImageOption,
            }}
          >
            {children}
          </CLotImageConfigurationOptions.Provider>
        </CThemeConfigurationOptions.Provider>
      </CTextConfigurationOptions.Provider>
    </CCurrencyConfigurationOptions.Provider>
  );
}
