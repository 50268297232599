import { useEffect, useState } from 'react';

import { catalogService } from 'src/common/services/catalog';
import { getObjectByKeys } from 'src/common/utils';

import { TLotsByLotID } from './types';

/**
 * A hook that fetches default currency code and lots from catalog service once the
 * component in which it is being used mounts and sets both of these values in state
 * @param catalogRef: string, catalog reference
 * @returns: lots and default currency code
 **/
export function useFetchCurrencyCodeAndLots(catalogRef: string): [boolean, TLotsByLotID, string] {
  const [areLotsLoading, setAreLotsLoading] = useState<boolean>(false);
  const [lots, setLots] = useState<TLotsByLotID>({});
  const [defaultCurrencyCode, setDefaultCurrencyCode] = useState<string>('');

  useEffect(() => {
    /**
     * Fetches catalog info and set lots and currency in state. Created to
     * call async logic in the use Effect and is immediately invoked.
     * */
    async function fetchLots() {
      setAreLotsLoading(true);
      const catalog = await catalogService.fetchCatalog(
        catalogRef as string,
        `{
          currencyCode
          lots {
            lotID
            lotRef
            lotNumber
            primaryImage {
              largeUrl
            }
          }
        }`,
      );

      setLots(getObjectByKeys(catalog?.lots, 'lotID'));
      setDefaultCurrencyCode(catalog?.currencyCode || '');
      setAreLotsLoading(false);
    }

    fetchLots();
  }, []);

  return [areLotsLoading, lots, defaultCurrencyCode];
}
