import { TBrokerMessageData } from 'src/common/services/clients/websockets/types';
/**
 * A utility function to get lot information
 * @param lotData: data to be compiled into specific lot information
 * @returns: returns specific lot information
 */
export const getLotData = (lotData: TBrokerMessageData) => {
  const { lotNumber, itemId, currentBid, askPrice, currentWinner } = lotData?.items[0] || {};
  const { auctionId } = lotData || {};

  return {
    auctionId,
    lotNumber,
    itemId,
    currentBid,
    askPrice,
    currentWinner,
  };
};
