export enum BID_TITLES {
  ACCEPTED_BID = 'Bid',
  SUBMITTED_BID = 'New Bid',
}

export type bidTitleType = BID_TITLES.ACCEPTED_BID | BID_TITLES.SUBMITTED_BID;

export interface IBoardBidProps {
  bidTitle: bidTitleType;
  bidAmount?: number;
  animationClass?: string;
}
