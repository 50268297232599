import { gql } from '@apollo/client';
import GQLService from '../../../common/services/clients/gql';
import { TExchangeRate, TExchangeRateResponse } from './types';

/**
 * ExchangeRatesService contains Exchange Rates related GQL queries
 * */
class ExchangeRatesService extends GQLService {
  /**
   * Executes exchange rate query to fetch exchange rates
   * @param currencyCode
   */
  fetchExchangeRates = async (currencyCode: string): Promise<Array<TExchangeRate>> => {
    const exchangeRatesQuery = gql`
      query ExchangeRatesQuery($currencyCode: String!) {
        exchangeRates(currencyCode: $currencyCode) {
          data {
            exchangeRates {
              curName
              curRate
            }
          }
        }
      }
    `;

    const exchangeRateResponse = await this.query(exchangeRatesQuery, { currencyCode });
    return (exchangeRateResponse.data as TExchangeRateResponse)?.exchangeRates.data.exchangeRates;
  };
}

export const exchangeRatesService = new ExchangeRatesService();
