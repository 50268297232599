import React, { useContext } from 'react';
import { CLotImageConfigurationOptions, CThemeConfigurationOptions } from '../../../contexts';

import styles from '../configuration.module.scss';
import { LOT_IMAGE_OPTIONS, THEME_MODE } from './types';

export function BackgroundColorOptions() {
  const { theme, setTheme } = useContext(CThemeConfigurationOptions);
  const { lotImageOption } = useContext(CLotImageConfigurationOptions);

  function themeModeToggler(event: React.SyntheticEvent) {
    const selectedTheme = (event.target as HTMLInputElement).value as THEME_MODE;
    if (selectedTheme !== theme) setTheme(selectedTheme);
  }

  const isBackgroundColorOptionDisabled = lotImageOption === LOT_IMAGE_OPTIONS.LOT_IMAGE_AND_TEXT;

  return (
    <div className={styles['main-widget']}>
      <h4>Background color options:</h4>
      <form className={styles['radiobox-list']}>
        <ul>
          <li>
            <label>
              <input
                disabled={isBackgroundColorOptionDisabled}
                type='radio'
                value={THEME_MODE.DARK}
                checked={theme === THEME_MODE.DARK}
                onChange={themeModeToggler}
                name='bgOption'
              />
              Black
            </label>
          </li>

          <li>
            <label>
              <input
                disabled={isBackgroundColorOptionDisabled}
                value={THEME_MODE.LIGHT}
                checked={theme === THEME_MODE.LIGHT}
                onChange={themeModeToggler}
                type='radio'
                name='bgOption'
              />{' '}
              White
            </label>
          </li>
        </ul>
      </form>
    </div>
  );
}
