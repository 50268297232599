import { Helmet } from 'react-helmet-async';

import { IHeaderMetaProps } from './types';

export const Metadata: React.FC<IHeaderMetaProps> = ({ title }) => {
  return (
    <Helmet>
      <title> {title} </title>
    </Helmet>
  );
};
