import { useEffect, useState } from 'react';
import { TEXT_OPTIONS, TTextOptions } from '../components/configuration-options/slide-menu/types';

/**
 * A hook that sets the selected text options to and from the session storage.
 * @returns: textOption and textOptionSetter
 **/
export function useTextOptions(catalogRef: string) {
  const [textOption, setTextOption] = useState<TTextOptions>(TEXT_OPTIONS.SHOW_BID);

  /**
   * A function that sets the selected text options to the session storage
   * and sets the textOption state.
   **/
  const setTextOptionWithSessionStorage = (selectedTextOption: TTextOptions) => {
    sessionStorage.setItem(`${catalogRef}-selectedTextOptions`, JSON.stringify(selectedTextOption));
    setTextOption(selectedTextOption);
  };

  useEffect(() => {
    const selectedTextOptionInSS = sessionStorage.getItem(`${catalogRef}-selectedTextOptions`);
    if (selectedTextOptionInSS) setTextOption(JSON.parse(selectedTextOptionInSS));
  }, [catalogRef]);

  return { textOption, setTextOption: setTextOptionWithSessionStorage };
}
