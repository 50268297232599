import { ICurrencyRowProps } from './types';

import styles from './bid-section.module.scss';

export function Bid({ title, amount, bold }: ICurrencyRowProps) {
  return (
    <div className={`${styles['bas-currency-row']} ${bold && styles['main-bold']}`}>
      <span className={styles['currency-label']}>{title}</span>
      <span className={styles['currency-amount']}>{amount?.toLocaleString()}</span>
    </div>
  );
}
