import React, { useContext } from 'react';

import { CCurrencyConfigurationOptions, CTextConfigurationOptions } from 'src/saleroom/contexts';

import { CURRENCY_OPTIONS_ON_SHOW_BID, CURRENCY_OPTIONS_ON_SHOW_BID_AND_ASK } from './constants';
import { TEXT_OPTIONS } from './types';

import styles from '../configuration.module.scss';

export function CurrencyOptions() {
  const { currencies, selectedCurrencies, setSelectedCurrencies } = useContext(CCurrencyConfigurationOptions);
  const { textOption } = useContext(CTextConfigurationOptions);

  const allowedCurrencies =
    textOption === TEXT_OPTIONS.SHOW_BID ? CURRENCY_OPTIONS_ON_SHOW_BID : CURRENCY_OPTIONS_ON_SHOW_BID_AND_ASK;

  if (selectedCurrencies.length > allowedCurrencies) {
    setSelectedCurrencies(selectedCurrencies.slice(0, allowedCurrencies));
  }

  function currencyToggleHandler(event: React.SyntheticEvent) {
    const toggledCurrency = (event.target as HTMLInputElement).name;
    if (selectedCurrencies.indexOf(toggledCurrency) < 0 && selectedCurrencies.length < allowedCurrencies) {
      setSelectedCurrencies(selectedCurrencies.concat([toggledCurrency]));
    } else {
      setSelectedCurrencies(selectedCurrencies.filter(currency => currency !== toggledCurrency));
    }
  }

  const currenciesList = currencies.map(currency => (
    <li key={currency}>
      <label>
        <input
          checked={selectedCurrencies.includes(currency)}
          name={currency}
          onChange={currencyToggleHandler}
          type='checkbox'
        />
        {currency}
      </label>
    </li>
  ));

  return (
    <div className={styles['main-widget']}>
      <form className={styles['checkbox-list']}>
        <ul>{currenciesList}</ul>
      </form>
    </div>
  );
}
