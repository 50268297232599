import { IBoardFooterProps } from './types';
import styles from './board-footer.module.scss';

export function BoardFooter({ lotNumber, lotBidder, animationClass = '' }: IBoardFooterProps) {
  return (
    <div className={styles['footer']}>
      <span className={styles['lot-number']}>{lotNumber}</span>
      <span className={`${styles['lot-bidder']} ${animationClass}`}>{lotBidder}</span>
    </div>
  );
}
