import { ENV } from 'src/common/configs/env';

export const WEBSOCKET_ENDPOINTS = {
  BROKER: `${ENV.WEBSOCKET_BROKER_BASE_URL}/am2b/ws`,
  BROKER_SUBSCRIPTION: '/topic/messages',
};

export const RADIX_FOR_WS_CONNECTION_IDENTIFIER = 30;

export const MS_IN_A_SECOND = 1000;

export const INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS = 10;

export const STOMP_CONFIG = {
  INIT_RECONNECTION_DELAY_MS: 3 * MS_IN_A_SECOND,
  HEARTBEAT_INCOMING_MS: 10 * MS_IN_A_SECOND,
  HEARTBEAT_OUTGOING_MS: 0,
  DISCARD_WEBSOCKET: true,
};
