import { useEffect, useState } from 'react';
import { CLEAR_STATUS_BUFFER } from './constants';
import styles from './lot-status.module.scss';
import { ILotStatusProps } from './types';

export function LotStatus({ status }: ILotStatusProps) {
  const [showStatus, setShowStatus] = useState(!!status);

  useEffect(() => {
    setShowStatus(true);
    const timeId = setTimeout(() => {
      setShowStatus(false);
    }, 5000 + CLEAR_STATUS_BUFFER);

    return () => {
      clearTimeout(timeId);
    };
  }, [status]);

  return <div className={styles['status']}>{showStatus && <strong>{status}</strong>}</div>;
}
